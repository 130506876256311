import { z } from 'zod'

const varsSchema = z.object({
  /** includes path: "api/ca/v0" */
  VITE_API_BASE_URL: z.string({
    required_error: 'Environment variable VITE_API_BASE_URL is not defined!',
  }),
  /** includes path: "api/sso/v0" */
  VITE_SSO_BASE_URL: z.string({
    required_error: 'Environment variable VITE_SSO_BASE_URL is not defined!',
  }),
  VITE_API_SSO_BASE_URL: z.string({
    required_error:
      'Environment variable VITE_API_SSO_BASE_URL is not defined!',
  }),
  VITE_DEV_MODE: z.string().nullish(),
  VITE_GITHUB_APP_BASE_URL: z.string({
    required_error:
      'Environment variable VITE_GITHUB_APP_BASE_URL is not defined!',
  }),
  VITE_BITBUCKET_ADDON_KEY: z.string({
    required_error:
      'Environment variable VITE_BITBUCKET_ADDON_KEY is not defined!',
  }),
  VITE_GITLAB_0AUTH_CLIENT_ID: z.string({
    required_error:
      'Environment variable VITE_GITLAB_0AUTH_CLIENT_ID is not defined!',
  }),
  VITE_STRIPE_KEY: z.string({
    required_error: 'Environment variable VITE_STRIPE_KEY is not defined!',
  }),
  VITE_DOCS_BASE_URL: z.string({
    required_error: 'Environment variable VITE_DOCS_BASE_URL is not defined!',
  }),
  VITE_TOOLS_BASE_URL: z.string({
    required_error: 'Environment variable VITE_TOOLS_BASE_URL is not defined!',
  }),
  VITE_INTERCOM_KEY: z.string({
    required_error: 'Environment variable VITE_INTERCOM_KEY is not defined!',
  }),
  VITE_API_USERWAY_BASE_URL: z.string({
    required_error:
      'Environment variable VITE_API_USERWAY_BASE_URL is not defined!',
  }),
})

function extract(source: unknown) {
  const res = varsSchema.safeParse(source)

  if (res.success) {
    return res.data
  }

  console.warn('Incomplete environment setup detected →', res.error.message)
  throw res.error
}

export const vars = extract(import.meta.env)

export const _dev_mode_ = vars.VITE_DEV_MODE === 'on'
