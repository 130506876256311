import { q } from '~/lib/query'

export const loader = q.loader((_) => async () => {
  // Remove after verify in dev

  // const user = auth.getUserPayload()
  // const { organizationSlug } = getOrganizationParams(params)
  // if (user?.organizationSlug !== organizationSlug) {
  //   const url = new URL(request.url)
  //   const search = `?next=${url.pathname}${url.search}`
  //   return redirect(`/${organizationSlug}/switch${search}`)
  // }
  return null
})
