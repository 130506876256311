import * as React from 'react'

interface AccessibleIconProps {
  children?: React.ReactNode
  label: string
}

export function AccessibleIcon(props: AccessibleIconProps) {
  const { children, label } = props
  const child = React.Children.only(children)
  return (
    <>
      {React.cloneElement(child as React.ReactElement, {
        'aria-hidden': 'true',
        focusable: 'false',
      })}
      <span className="sr-only">{label}</span>
    </>
  )
}
