import { z } from 'zod'

function isPlainObject(
  value: unknown,
): value is Record<string | number | symbol, unknown> {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    !(value instanceof Date)
  )
}

/**
 * Creates zod schema for a record with enum keys.
 * `z.record` won't work with enums properly and make the inferred type Partial.
 * @see https://github.com/colinhacks/zod/issues/2623
 */
export function zodEnumRecord<
  RecordKey extends z.ZodNativeEnum<any>,
  RecordValue extends z.ZodTypeAny,
>(keySchema: RecordKey, valueSchema: RecordValue) {
  type OfRecord = Record<z.infer<RecordKey>, z.infer<RecordValue>>

  const enumValues = Object.values(keySchema._def.values)

  return z.custom<OfRecord>(
    (input: unknown) => {
      if (!isPlainObject(input)) {
        return false
      }

      for (const enumValue of enumValues) {
        if ((enumValue as keyof typeof input) in input) {
          const inputValue = input[enumValue as keyof typeof input]
          const { success } = valueSchema.safeParse(inputValue)
          if (!success) {
            return false
          }
        } else {
          return false
        }
      }

      return true
    },
    (data) => {
      try {
        const input = JSON.stringify(data, null, 2)
        const keys = JSON.stringify(enumValues, null, 2)
        const value = JSON.stringify(valueSchema._def, null, 2)
        const message = `[zodEnumRecord]\nInput\n${input}\nExpected keys\n${keys}\nExpected value def\n${value}`
        return { message }
      } catch {
        return {
          message: `[zodEnumRecord]\nFailed to parse ${JSON.stringify(data, null, 2)}`,
        }
      }
    },
  )
}
