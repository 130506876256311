import { queryOptions } from '@tanstack/react-query'
import { getExtendedToken } from '~/feat/auth/api'
import { time } from '~/lib/utils/time'

export const authQueries = {
  all: ['auth'] as const,
  extendedToken: () => {
    return queryOptions({
      queryKey: [...authQueries.all, 'extended-token'],
      queryFn: () => {
        return getExtendedToken()
      },
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
}
