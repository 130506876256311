import { useCallback, useState } from 'react'

/**
 * @example
 * const [copy, copiedText] = useClipboard()
 *
 * <button onClick={() => copy('steve@apple.com')}>Copy email</button>
 */
export function useClipboard() {
  const [state, setState] = useState<string | null>(null)

  const copy = useCallback(async function copy(
    content: string,
  ): Promise<{ ok: boolean }> {
    if (!navigator?.clipboard) {
      console.warn('"navigator.clipboard" is not supported')
      return { ok: false }
    }
    try {
      await navigator.clipboard.writeText(content)
      setState(content)
      return { ok: true }
    } catch (error) {
      console.warn('copy failed', error)
      setState(null)
      return { ok: false }
    }
  }, [])

  return [copy, state] as const
}
