import { GrantedMemberRole, LoginBody, SignUpBody } from '@userway/cicd-api'
import { z } from 'zod'
import { http, httpApiSso, httpGuest, httpSso } from '~/lib/http'
import { q } from '~/lib/query'

const loginResponseSchema = z.object({
  payload: z.object({
    accessToken: z.string(),
    refreshToken: z.string().nullable(),
    organizationSlug: z.string(),
  }),
})

export const ACCOUNT_OWNER_SYMBOL = Symbol('ACCOUNT_OWNER')

export const restrictionsSchema = z
  .array(
    z.object({
      roleName: z.nativeEnum(GrantedMemberRole),
      roleScope: z.array(z.any()),
    }),
  )
  .optional()
  .transform((value) => {
    if (value === undefined) {
      return ACCOUNT_OWNER_SYMBOL
    }
    return value
  })

export async function login(payload: LoginBody, signal?: AbortSignal | null) {
  const res = await httpGuest.post('login', {
    json: { ...payload, verificationCode: null, mfaMethod: null },
    signal: signal || null,
  })
  const json = await res.json()
  return loginResponseSchema.parse(json)
}

export async function organizationLogin(
  organizationSlug: string,
  payload: LoginBody,
  signal?: AbortSignal | null,
) {
  const res = await httpGuest.post(`${organizationSlug}/login`, {
    json: { ...payload, verificationCode: null, mfaMethod: null },
    signal: signal || null,
  })
  const json = await res.json()
  return loginResponseSchema.parse(json)
}

export async function exchange(authCode: string) {
  const searchParams = new URLSearchParams()
  searchParams.set('auth-code', authCode)
  const json = await httpGuest
    .get(`auth-code-exchange`, { searchParams })
    .json()
  return loginResponseSchema.parse(json)
}

const registerResponseSchema = q.responseSchema(
  z.object({
    auth: z.object({
      accessToken: z.string(),
      refreshToken: z.string().nullable(),
    }),
    email: z.string(),
    slug: z.string(),
    ssoUserId: z.string(),
    accountCode: z.string(),
  }),
)

export async function register(payload: SignUpBody) {
  const res = await http.post('signup', { json: payload }).json()
  return registerResponseSchema.parse(res)
}

const getExtendedTokenResponseSchema = q.responseSchema(
  z.object({
    targetAccounts: z.array(
      z.object({
        targetSsoUserId: z.string(),
        targetAccountCode: z.string(),
        targetAccountSlug: z.string(),
        restrictions: restrictionsSchema,
      }),
    ),
    // There are more fields in the object, I'm validating only ones that we are using at the moment
  }),
)
export async function getExtendedToken() {
  const res = await httpApiSso.get('user/extended-jwt').json()
  return getExtendedTokenResponseSchema.parse(res)
}

interface SendResetPassEmailBody {
  resetPasswordEmail: string
  redirectUrl: string
  resetCodeDeliveryType: 'LINK'
}
export async function sendResetEmail(
  payload: SendResetPassEmailBody,
  signal?: AbortSignal | null,
) {
  const res = await httpApiSso.post(
    'public/service-user/password-reset-email',
    {
      json: payload,
      signal: signal || null,
    },
  )
  const json = await res.json()
  return json
}

interface ResetPasswordBody {
  resetPasswordCode: string
  resetNewPassword: string
}
export async function resetPassword(
  payload: ResetPasswordBody,
  signal?: AbortSignal | null,
) {
  const res = await httpSso.post('public/service-user/reset-password', {
    json: payload,
    signal: signal || null,
  })
  const json = await res.json()
  return json
}
